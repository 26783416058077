.sign-in {
  width: 30%;
  margin: auto;
  vertical-align: middle;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, .25);
}

.c-main {
  margin-top: 100px;
  margin-left: 100px;
}

.center {
  margin-top: 0;
  padding: 0%;
  text-align: center;
  color: white;
  background-color: #97cdf3;
}

.card {
  width: 200px;
  height: 230px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
}

.class_form {
  display: flex;
  flex-direction: column;
}

.class_form div:nth-child(1) {
  text-align: left;
  margin-left: 20px;
}

.class_form div:nth-child(2) {
  text-align: right;
}


.class_form_buttons {
  display: flex;
  flex-direction: column;
}

.position_center {
  text-align: center;
}

.emailform {
  display: flex;
  flex-direction: column;
}


.position_margin {
  margin: 50px;
}

.tyle_arrangement {
  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.button_design {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}

.button_margin {
  margin-right: 100px;
}

.insert_margin {
  margin: 10px
}

.text_field {
  display: flex;
}

.card_content {
  display: flex;
  margin-left: 10px;
}

.icon_design {
  display: flex;
  align-items: center;
}

.text_width {
  display: flex;
  width: 80%;
  text-align: justify;
}

.insert_card {
  font-size: 0.5em;
  width: 90px;
  height: 30px;
  margin: 1px;
  padding: 5px;
  flex-direction: row;
  text-align: justify;
}

.insert_card_class {
  font-size: 0.9em;
  width: 200px;
  height: 60px;
  margin: 1px;
  padding: 5px;
  flex-direction: row;
  text-align: justify;
}

.insert_card_mergin {
  margin: 3px
}

.insert_card_class:hover {
  background-color: #97cdf3;
}

.insert_card:hover {
  background-color: #97cdf3;
}

.left {
  margin-right: 10px;
  text-align: center;
  width: 100%;
  flex-direction: row;
}

.right {
  margin: 0;
  display: flex;
  text-align: right;
  flex-direction: column;
}

.panel {
  margin: 0;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
}

.logo {
  display: flex;
  text-align: left;

}

.indicator {
  margin: 0;
  flex-direction: row;
  display: flex;
  padding: 10px;
}

.logo-image {
  margin-right: 5px;
}

.button_publish {
  margin-left: 20px;
  padding: 10px;
  display: flex;
}

.button_sendMailToAll {
  text-align: left;
  justify-content: flex-start;
}

.vertical_buttons {
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.m-3 {
  margin: 3px
}

.m-1 {
  margin: 1px
}


.horizontal {
  display: flex;
}





.horizontal div:nth-child(1) {
  font-size: 15px;
}

.horizontal div:nth-child(2) {
  font-size: 15px;
}

.horizontal div:nth-child(3) {
  font-size: 15px;

}